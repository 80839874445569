<template>
  <div class="row">
    <div class="col-12 text-right">
      <h5 v-if="alteraddress" :title="helloaddress">
        Hello {{ alteraddress }}!
      </h5>
    </div>

    <div class="col-12">
      <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">
                <span class="">Price</span> vs supply
              </h5>
              <h2 class="card-title">
                <i class="tim-icons icon-chart-bar-32 text-primary "></i>$SCRT
              </h2>
            </div>
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="" style="float:right">
                <span style="color: #E04ECA;">SCRT</span
                ><span style="color: #FFF;">.FI</span>
              </h5>
            </div>
          </div>
        </template>
        <div class="chart-area" data-bg-text="scrt.fi">
          <line-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
    </div>

    <div class="col-sm-12">
      <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">Price vs supply</h5>
              <h2 class="card-title">
                <i class="tim-icons icon-chart-bar-32 text-primary "></i>$SEFI
              </h2>
            </div>
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="" style="float:right">
                <span style="color: #E04ECA;">SCRT</span
                ><span style="color: #FFF;">.FI</span>
              </h5>
            </div>
          </div>
        </template>
        <div class="chart-area" data-bg-text="scrt.fi">
          <line-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
      <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">Price vs supply</h5>
              <h2 class="card-title">
                <i class="tim-icons icon-chart-bar-32 text-primary "></i>$SEFI
              </h2>
            </div>
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="" style="float:right">
                <span style="color: #E04ECA;">SCRT</span
                ><span style="color: #FFF;">.FI</span>
              </h5>
            </div>
          </div>
        </template>
        <div class="chart-area" data-bg-text="scrt.fi">
          <line-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
      <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">Price vs supply</h5>
              <h2 class="card-title">
                <i class="tim-icons icon-chart-bar-32 text-primary "></i>$SEFI
              </h2>
            </div>
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="" style="float:right">
                <span style="color: #E04ECA;">SCRT</span
                ><span style="color: #FFF;">.FI</span>
              </h5>
            </div>
          </div>
        </template>
        <div class="chart-area" data-bg-text="scrt.fi">
          <line-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
    </div>

    <div class="col-12 col-sm-12">
      <div class="row">
      <div class="col-lg-6 col-sm-12 p-0">
        <!-- Stats Cards -->
        <div class="col-sm-12">
          <stats-card
            :title="scrtbalance"
            sub-title="Your $scrt balance"
            icon="scrt-icon"
            class="truncate"
          >
          </stats-card>
        </div>
        <div class="col-sm-12">
          <stats-card
            :title="sefiamount"
            sub-title="Your $sefi balance"
            type=""
            icon="sefi-icon"
          >
          </stats-card>
        </div>
        <div class="col-sm-12">
          <stats-card
            :title="buttamount"
            sub-title="Your $butt balance"
            type="warning"
            icon="tim-icons icon-chat-33"
            class="truncate"
          >
          </stats-card>
        </div>
        <div class="col-sm-12">
          <stats-card
            :title="cshbkamount"
            sub-title="Your $CSHBK balance"
            type=""
            icon="cshbk-icon"
          >
          </stats-card>
        </div>
        <div class="col-sm-12">
          <stats-card
            :title="cshbkamount"
            sub-title="Your $CSHBK balance"
            type=""
            icon="cshbk-icon"
          >
          </stats-card>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6">
        <card type="tasks" :header-classes="{ 'text-right': isRTL }">
          <template slot="header">
            <h2 class="title d-inline">Pools</h2>
          </template>
          <div class="table-full-width table-responsive"></div>
        </card>
      </div>
    </div>
    </div>
    <div class="col-lg-6">
      <card type="tasks" :header-classes="{ 'text-right': isRTL }">
        <template slot="header">
          <h2 class="title d-inline">SNIP20 history</h2>
        </template>
        <div class="table-full-width table-responsive">
          <lptokens-list></lptokens-list>
        </div>
      </card>
    </div>

    <div class="col-lg-6">
      <card type="tasks" :header-classes="{ 'text-right': isRTL }">
        <template slot="header">
          <h2 class="title d-inline">LP tokens</h2>
        </template>
        <div class="table-full-width table-responsive">
          <lptokens-list></lptokens-list>
        </div>
      </card>
    </div>

    <div class="col-lg-6">
      <card type="tasks" :header-classes="{ 'text-right': isRTL }">
        <template slot="header">
          <h2 class="title d-inline">TVL</h2>
        </template>
        <div class="table-full-width table-responsive">
          <lptokens-list></lptokens-list>
        </div>
      </card>
    </div>

    <div class="col-lg-12">
      <card type="tasks" :header-classes="{ 'text-right': isRTL }">
        <template slot="header">
          <h2 class="title d-inline">NFTs</h2>
        </template>
        <div class="table-full-width table-responsive">
          <nft-list></nft-list>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import PoolsList from "./PoolsList";
import LptokensList from "./LptokensList";
import NftList from "./NftList";

import StatsCard from "src/components/Cards/StatsCard";
import TokensCard from "src/components/Cards/TokensCard";

import config from "@/config";
import axios from "axios";
import sefiPageVue from "../Sefi/sefiPage.vue";
const { SigningCosmWasmClient } = require("secretjs");

let bigChartData = [
  [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
  [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
  [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
];
let bigChartLabels = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: "rgba(255,255,255,0)",
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4
};
let bigChartDatasetOptions2 = {
  fill: true,
  borderColor: config.colors.danger,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.danger,
  pointBorderColor: "rgba(255,255,255,0)",
  pointHoverBackgroundColor: config.colors.danger,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4
};

export default {
  components: {
    LineChart,
    StatsCard,
    LptokensList,
    NftList,
    TokensCard
  },
  data() {
    return {
      scrtbalance: "-",
      sefiamount: "-",
      buttamount: "-",
      sdexbalance: "-",
      cshbkamount: "-",
      siennaamount: "-",
      helloaddress: "",
      alteraddress: "",
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: bigChartData[2]
            },
            {
              ...bigChartDatasetOptions,
              data: bigChartData[1]
            }
          ],
          labels: bigChartLabels
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      }
    };
  },

  async created() {
    try {
      window.onload = async () => {
        this.chainId = "secret-2";
        var self = this;
        if (!window.getOfflineSigner || !window.keplr) {
          alert("Please install keplr extension");
        } else {
          if (window.keplr.experimentalSuggestChain) {
            try {
              const keplrOfflineSigner = window.getOfflineSigner(this.chainId);
              const accounts = await keplrOfflineSigner.getAccounts();
              this.address = accounts[0].address;
              this.helloaddress = this.address;
              this.alteraddress = this.helloaddress.replace(
                this.helloaddress.substring(13, 39),
                "****"
              );

              this.cosmJS = new SigningCosmWasmClient(
                "https://bridge-api-manager.azure-api.net/",
                this.address,
                keplrOfflineSigner,
                window.getEnigmaUtils(this.chainId),
                {
                  init: {
                    amount: [{ amount: "300000", denom: "uscrt" }],
                    gas: "300000"
                  },
                  exec: {
                    amount: [{ amount: "300000", denom: "uscrt" }],
                    gas: "300000"
                  }
                }
              );

              this.account = await this.cosmJS.getAccount(this.address);

              function isDenomScrt(balance) {
                return balance.denom === "uscrt";
              }
              const scrtbalance = this.account.balance.find(isDenomScrt);

              let scrtamount = 0;

              if (scrtbalance) {
                scrtamount =
                  scrtbalance.amount > 0 ? scrtbalance.amount / 10 ** 6 : 0;
              }

              this.scrtbalance = scrtamount.toString();

              const seficontract =
                "secret15l9cqgz5uezgydrglaak5ahfac69kmx2qpd6xt";

              window.keplr
                .getSecret20ViewingKey(this.chainId, seficontract, self)
                .then(function(t) {
                  if (t) {
                    const query = {
                      balance: { key: t, address: accounts[0].address }
                    };
                    self.cosmJS
                      .queryContractSmart(seficontract, query)
                      .then(function(x) {
                        var a = x.balance.amount / Math.pow(10, 6);
                        self.sefiamount = a.toString();
                      });
                  } else {
                    self.sefiamount = "Missing viewkey. Create it.";
                  }
                });

              // butt

              const buttcontract =
                "secret1yxcexylwyxlq58umhgsjgstgcg2a0ytfy4d9lt";

              window.keplr
                .getSecret20ViewingKey(this.chainId, buttcontract, self)
                .then(function(t) {
                  if (t) {
                    const query = {
                      balance: { key: t, address: accounts[0].address }
                    };
                    self.cosmJS
                      .queryContractSmart(buttcontract, query)
                      .then(function(x) {
                        var a = x.balance.amount / Math.pow(10, 6);
                        self.buttamount = a.toFixed(2).toString();
                      });
                  } else {
                    self.buttamount = "Missing viewkey. Create it.";
                  }
                });

              // cshbk
              const cshbkcontract =
                "secret1eckd8jyjjz5qvse3t42qnepy4ywg9qj6hlh46s";

              window.keplr
                .getSecret20ViewingKey(this.chainId, cshbkcontract, self)
                .catch(function(e)
                { 
                  self.cshbkamount = 'fuck';
                  throw new Error('Something is missing');
               }) 
                .then(function(t) {
                    const query = {
                      balance: { key: t, address: accounts[0].address }
                    };
                    self.cosmJS
                      .queryContractSmart(cshbkcontract, query)
                      .then(function(x) {
                        var a = x.balance.amount / Math.pow(10, 6);
                        self.cshbkamount = a.toString();
                      });
                })
            } catch (error) {
              console.log(error);
            }
          } else {
            alert("Please use the recent version of keplr extension");
          }
        }
      };
    } catch (e) {
      this.errors.push(e);
    }
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            ...bigChartDatasetOptions,
            data: bigChartData[index]
          },
          {
            ...bigChartDatasetOptions2,
            data: bigChartData[2]
          }
        ],
        labels: bigChartLabels
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    }
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = "ar";
      this.$rtl.enableRTL();
    }
    this.initBigChart(0);
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
  }
};

/// LP tokens
/// https://api-bridge-mainnet.azurewebsites.net/secretswap_pools/?page=0&size=1000
/// api_key_VdRvwDM4goc4hiwCBw4mOWh+6chxezZagw6ExhQAPgo=
/// https://scrthost.xiphiar.com:3443/pools/pair/secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek/secret1xcrf2vvxcz8dhtgzgsd0zmzlf9g320ea2rhdjw
// {"data":[{"api_id":28,"name":"SSCRT--SLINK",
//"swapContract":"secret1x8244a7l2fr642axef0sl5z3jw2pn75rp36hxs",
//"tokenAddress":"secret1rldr66767a4gz3adkq2vgndwgnxlfqqae4fgen",
//"spyAddress":"secret1mlv3av6nlqt3fmzmtw0pnehsff2dxrzxq98225",
////"asset1":"SSCRT","asset1decimals":6,"asset1address":"secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek","asset1cgid":"secret",
//"asset2":"SLINK","asset2decimals":18,"asset2address":"secret1xcrf2vvxcz8dhtgzgsd0zmzlf9g320ea2rhdjw","asset2cgid":"chainlink"}]}{

//https://scrthost.xiphiar.com:3443/pools/pair/secret17w6pnfv0yn9k99dc8wttz5h0e0e6hz5pt8k2yu/secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek
//{"data":[{"api_id":1269,"name":"dSCRT--SSCRT","swapContract":"secret183lq5e49veyushgu5s2ul3dup57z4qcacx7ek8","tokenAddress":"secret1sv23mqdqammv9zdxgp33nk6wqgl07ly92lh5px","spyAddress":null,
//"asset1":"SSCRT","asset1decimals":6,"asset1address":"secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek","asset1cgid":"secret",
//"asset2":"dSCRT","asset2decimals":6,"asset2address":"secret17w6pnfv0yn9k99dc8wttz5h0e0e6hz5pt8k2yu","asset2cgid":null}]}

/// coingecko SCRT https://api.coingecko.com/api/v3/simple/price?ids=secret&vs_currencies=USD
/// coingecko SEFI https://api.coingecko.com/api/v3/simple/price?ids=sefi&vs_currencies=USD
</script>
<style></style>
